import {
  Box,
  Image,
  Text,
  Heading,
  Link,
  HStack,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby'
import React from "react";
import Layout from '../components/Layout';
import Wrapper from '../components/atoms/Wrapper';
import ClientOnly from '../components/atoms/ClientOnly';
import SocialIcon from '../components/atoms/SocialIcon';
import wordmarkSrc from '../assets/wordmark.svg';
import shareImageSrc from '../assets/share-image.png';
import facebookSrc from '../assets/facebook.svg';
import twitterSrc from '../assets/twitter.svg';
import downloadSrc from '../assets/download.svg';

const isBrowser = typeof window !== "undefined";

const SharePage = ({ location }) => {
  const shareURL = isBrowser ? window.location.href : '';

  return (
    <Layout 
      location={location}
      seo={{
        meta_image: location.origin + shareImageSrc,
        og_type: 'website',
        og_title: 'adidas JDZX SS22',
        twitter_card: 'summary'
      }}>
        <Box>
          <Wrapper>
            <Box textAlign="center" mb="6">
              <Heading size="md" mb="0">
                You’ve reached
              </Heading>
              <Image src={wordmarkSrc} alt="The Next Destination" mx="auto" my="6" px="4"/>
              <Text fontSize="sm" textTransform="uppercase">
                Show this at the till to get your exclusive reward With adidas purchases over £50
              </Text>
            </Box>
            <Box position="relative" width="108%" ml="-4%" mb="6">
              <Image width="100%" src={shareImageSrc} alt="Share image"/>
            </Box>
            <Box textAlign="center">
              <Heading size="md" mb="2">
                Share your VIP Boarding Pass
              </Heading>
              <Text fontSize="sm" textTransform="uppercase" mb="6">#JDZX2022</Text>
              <ClientOnly>
                <HStack spacing="6" justify="center" mb="6">
                  <SocialIcon dataPlatform="facebook" href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(shareURL)}`} src={facebookSrc} label="Facebook"/>
                  <SocialIcon dataPlatform="twitter" href={`https://twitter.com/intent/tweet?text=${encodeURIComponent("adidas JDZX SS22")}&url=${encodeURIComponent(shareURL)}`} src={twitterSrc} label="Twitter"/>
                  <SocialIcon dataPlatform="download" href={shareImageSrc} src={downloadSrc} label="Download" download={true}/>
                </HStack>
              </ClientOnly>
              <Link as={GatsbyLink} to="/" variant="underline">Back</Link>
            </Box>
          </Wrapper>
        </Box>
    </Layout>
  );
}

export default SharePage
